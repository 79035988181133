import { apiBaseUrl } from "../config/constant";
import axios from "axios";
import { setSessionForModal } from "../utils/Helper";

export const placedAnOrder = async(formValues)=>{

  console.log(formValues,"formValues________")
  // return ;
    const response = await fetch(apiBaseUrl + "fnb/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });
      const data = await response.json();
      return data;
}

export const getOrderId = async () => {
  try {
    const response = await axios.get(apiBaseUrl + '/fnb/get_order_id');
    return response.data.response.result;
  } catch (error) {
    console.error(error);
  }
}
export function hasOtherItemsWithDifferentId(currentItemId,cartItems) {
  // Filter items with a different ID
  const otherItems = cartItems.filter(item => item.itemid !== currentItemId);

  // Check if the filtered array has any items
  return otherItems.length > 0;
}
export function belongsToSpecificCategories(currentItemCategoryId, categoryIdsToCheck) {
  // Check if the current item category ID is in the specified set
  return categoryIdsToCheck.includes(currentItemCategoryId);
}

// this function is for give latitude , longitude using pincode

export const findRestaurantPetpoojaIdByPincode = async (pincode) => {
  try {
    const response = await axios.get(apiBaseUrl + 'fnb/getRestaurantList');
    const restaurantListData = response?.data?.response?.result;

    for (const restaurant of restaurantListData) {
      for (const location of restaurant.deliveryLocation) {
        if (location.pincode === parseInt(pincode)) {
          // Assuming setSessionForModal is defined somewhere
          setSessionForModal('deliveryLocation', restaurant.deliveryLocation);

          const rtnData = {
            latitude: restaurant.latitude,
            longitude: restaurant.longitude,
          };

          return rtnData;
        }
      }
    }

    // If pincode is not found
    return null;
  } catch (error) {
    console.error('Error while fetching restaurant data:', error);
    return null; // Return null or handle the error as per your requirement
  }
};


// Function to retrieve addons for an item
export const getAddonsForItem = (addonData, addonIds) => {
  const addons = [];
  addonIds.forEach(addonId => {
      const addon = addonData.find(addon => addon.addonid === addonId);
      if (addon) {
          addons.push(addon);
      }
  });
  return addons;
};



export const disableBookEventButton = (eventsData) => {
  console.log(eventsData, "cpmonon___)");

  // Ensure eventsData is valid and has at least one element with valid date and time
  if (!eventsData || !eventsData[0]?.eventDate || !eventsData[0]?.startTime) {
    return true; // Disable the button if any required data is missing
  }

  // Create a Date object for the event date and time
  const eventDateTime = new Date(eventsData[0].eventDate);

  // Extract and set hours and minutes from startTime
  const [hours, minutes] = eventsData[0].startTime.split(":").map(Number);
  eventDateTime.setHours(hours, minutes, 0, 0);

  const currentTime = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = eventDateTime - currentTime;

  // Convert the time difference to hours
  const hoursRemaining = timeDifference / (1000 * 60 * 60);

  // Disable the button if the remaining time is less than 24 hours
  return hoursRemaining < 24;
};

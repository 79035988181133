import React, { useState, useContext, useEffect } from "react";
import Header from "../components/HeaderMob";
import FoodOrderBanner from "../Sliders/foodOrderBanner";
import Dropdown from "react-bootstrap/Dropdown";
import ModalComponent from "../components/FnbModalCheck";
import Footer from "../components/FooterMob";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare, faHeart } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import FnbContext from "../contextApi/FnbContext";

import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Search from "../components/search";

const FoodOrder = () => {
  const {
    foodCategory,
    foodItem,
    onAdd,
    addon,
    cartItems,
    handleChangeIncDec,
    resturantDetails,
    soldOutItems,
  } = useContext(FnbContext);

  // serachbar
  const placeholders = [
    "Discover Workshops",
    "Order Fries",
    "Order Dips",
    "Order Apple Cider",
    "Buy Craft beer",
    "Buy Coasters",
    "Try Chicken Ghee Roast",
  ];
  const [counter, setCounter] = useState(0);
  const [placeholder, setPlaceholder] = useState(placeholders[counter]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter + 1) % placeholders.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [counter]);

  useEffect(() => {
    setPlaceholder(placeholders[counter]);
  }, [counter]);

  // end

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [extraItem, setExtraItem] = useState([]);
  const [temp, setTemp] = useState([]);
  const[NameItem,setNameItem]=useState('')
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleAdd = (item, index) => {
    if (item.addon && item.addon.length > 0) {
      // the addon array is not empty
      for (let i = 0; i < addon.length; i++) {
        if (item.addon[0].addon_group_id === addon[i].addongroupid) {
          setExtraItem([addon[i]]);
          setTemp(item);
          handleShow2();
          break;
        }
      }
    } else {
      // the addon array is empty
      onAdd(item, index);
      handleChangeIncDec(item, 1);
    }
  };
  const [selectedAddons, setSelectedAddons] = useState([]);
  const onUpdateField = (addonItem) => {
    if (selectedAddons.includes(addonItem)) {
      setSelectedAddons(selectedAddons.filter(item => item !== addonItem));
    } else {
      setSelectedAddons([...selectedAddons, addonItem]);
    }
  };

  // paggination
  const [selectedFilter, setSelectedFilter] = useState(null);
  const filteredItems = selectedFilter?foodItem?.filter((item) => item.item_categoryid === selectedFilter)
    : foodItem;

  const [foodItems, setFoodItems] = useState(filteredItems);
  // const foodItems = filteredItems
  // end paggination

  // start filataration
  const [selectedOption, setSelectedOption] = useState(null);
  const VegProduct = "veg";
  const NonVegProduct = "nonveg";

  const VegProductId = {
    categoryid: "69924",
  };

  const NonVegProductId = {
    categoryid: "69925",
  };

  const handleFilterClick = (item) => {
    setNameItem(item.categoryname)
    setSelectedFilter((prevFilter) => {
      // If the same category is clicked again, clear the filter
      if (prevFilter === item.categoryid) {
        setNameItem('')
        setFoodItems(filteredItems);
        return null;
      } else {
        setFoodItems(filteredItems);
        return item.categoryid;
      }
    });
  };
  const handleFilterClick2 = (option, item) => {
    if (option === selectedOption) {
      setSelectedOption(null); // clear selected option if clicked again
    } else {
      setFoodItems(filteredItems);
      setSelectedOption(option); // set selected option
    }
    handleFilterClick(item);
  };
  // end filtration

  // veg non veg filtration

  //endveg non veg filtration

  return (
    <>
      <ModalComponent />
      <div className="d_margin mb-4 pb-4 container-fluid">
        <Header />
        <Search />

        <div className=" ds-none  home-1st-col d-flex justify-content-around poppins  mt-2 ">
          <p>
            <Link to="/beer">
              <img
                src={process.env.PUBLIC_URL +  "./img/Bevrages_icon.png"}
                className="h-1st-col"
              />
              <br />
              Beverages
            </Link>
          </p>
          <p id="circle-effect">
            <img
              src={process.env.PUBLIC_URL +  "./img/Food_icon.png"}
              className="h-1st-col"
            />
            <br />
            Food
          </p>
          <p>
            <Link to="/merchandise">
              <img
                src={process.env.PUBLIC_URL + "/images/merch.png"}
                className="h-1st-col"
              />
              <br />
              Merchandise
            </Link>
          </p>
        </div>

        {/* body */}
        {/* <div className="d_main_title mt-4" style={{ paddingLeft: "3%" }}>
          Recommendation and best sellers
          <p className="d_main_sub_title ">
            Crowd favourites , post hits, latest pics
          </p>
        </div>
        <FoodOrderBanner /> */}

        <div
          className="h-food-menu d-flex justify-content-between"
          style={{
            border: "1px solid #d4d4d4",
            borderRadius: "5px",
            boxShadow: "2px 5px 5px 5px #f5f5f5",
            marginBottom: "2%",
          }}
        >
          <div>
            <p className=" d_main_title pd-2 ">
              Menu <h6 className="d_main_sub_title">{NameItem}</h6>
            </p>
          </div>
          <div>
            <div className="poppins d-flex  ">
              <label class="switch mt-2 ">
                <input
                  type="checkbox"
                  style={{ paddingLeft: "-10px" }}
                  checked={selectedOption === VegProduct}
                  onClick={() => handleFilterClick2(VegProduct, VegProductId)}
                />
                <span class="slider round"></span>
              </label>
              <span className="mt-1" style={{ paddingLeft: "3px" }}>
                Veg
              </span>
              <div className=" ms-2 d-flex" id="non-veg">
                <label class="switch mt-2  ">
                  <input
                    type="checkbox"
                    checked={selectedOption === NonVegProduct}
                    onClick={() =>
                      handleFilterClick2(NonVegProduct, NonVegProductId)
                    }
                  />
                  <span class="slider round"></span>
                </label>
                <span className="m-1" style={{ paddingLeft: "3px" }}>
                  Non-Veg
                </span>
              </div>
            </div>
            <br />
            {resturantDetails ? (
              <div className="location_resturant" id="mob_res_loc_food">
                <p>
                  <FontAwesomeIcon icon={faLocationDot} />
                  {resturantDetails.restaurantName}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Dropdown className="mt-2 mb-2">
          <Dropdown.Toggle
            variant="ffdsfdsfd"
            id="dropdown-split-basic"
            style={{ width: "100%", border: "1px solid #A2C760" }}
          >
            Categories
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "100%" }}>
            {foodCategory?.map((item, index) => (
              <Dropdown.Item key={index}>
                <li
                  key={item.categoryid}
                  id="fnb-category"
                  className={selectedFilter === item.categoryid ? "active" : ""}
                  onClick={() => handleFilterClick(item)}
                >
                  {item.categoryname}
                </li>
              </Dropdown.Item>
            ))}{" "}
          </Dropdown.Menu>
        </Dropdown>
        <div className="mb-4">
          {filteredItems?.map((item, id) => (
            <div key={id} className="food-menu-container" id="crtfood">
              <div className="food-details">
                {/* <ul className="d_order_list_name mb-0">
                  <li>
                    <span className="vage_curcul nonvag d-flex align-items-center justify-content-center position-relative">
                      <em></em>
                    </span>
                  </li>
                  <li
                    style={{
                      width: "70px",
                      height: "20px",
                      backgroundColor: "#f8e8de",
                      borderRadius: "5px",
                    }}
                  >
                    <p
                      className="poppins text-center"
                      style={{ color: "#dc8b5b", fontSize: "10px" }}
                    >
                      Bestsellers
                    </p>
                  </li>
                </ul> */}
                <p style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {/* In{" "}
                  {item.item_categoryid === "69914"
                    ? "Breakfast"
                    : item.item_categoryid === "69915"
                    ? "Starters"
                    : item.item_categoryid === "69916"
                    ? "Salads"
                    : item.item_categoryid === "69916"
                    ? "Main Course"
                    : "Desserts"} */}
                    {/* In {NameItem} */}
                </p>
                <b style={{width:"50%"}}>        {item.itemname}</b>
                
          
       
                {/* <img
                src={process.env.PUBLIC_URL + "/images/icons/star.jpg"}
                style={{ width: "70px", marginTop: "-8px" }}
              /> */}
<br/>
                <span className="description">{item.itemdescription.trim()}</span>
                <h6>{item.price}</h6>
              </div>

              <div className="image-container">
                <img
                  src={
                    item.item_image_url
                      ? item.item_image_url
                      : process.env.PUBLIC_URL + "/images/pro7.png"
                  }
                  alt=""
                />
                <br />
                {soldOutItems.includes(item.itemid) ? (
                  <div className="sold-out">Sold Out</div>
                ) : cartItems.find(
                    (cartItem) => cartItem.itemid === item.itemid
                  ) ? (
                  <div className="quantity-container">
                    <span onClick={() => handleChangeIncDec(item, -1)}>-</span>
                    <input
                      type="number"
                      value={
                        cartItems.find(
                          (cartItem) => cartItem.itemid === item.itemid
                        ).qty
                      }
                      readOnly
                    />
                    <span onClick={() => handleChangeIncDec(item, 1)}>+</span>
                  </div>
                ) : (
                  <button onClick={() => handleAdd(item, id)}>Add</button>
                )}
              </div>
            </div>
          ))}
        </div>
        {/* <div className="paggination-btn">
<button onClick={handleNextPage}>Next</button>
<button onClick={handlePrevPage}>Prev</button>
     
      <p>Page {currentPage} of {totalPages}</p>
</div> */}

        {/* <div class="d-flex justify-content-between food-menu mb-4 pb-4 poppins">
<div > 
<p style={{fontSize:"14px", color:'grey'}}>In Breakfast</p>
    <p style={{fontWeight:"600",lineHeight:"0px" }}>English Breakfast</p> 
    <img src={process.env.PUBLIC_URL + "/images/icons/star.jpg"} style={{width:"50px"}}/><br/>
<strong>₹ 380</strong>
<p style={{fontSize:"14px", color:'grey'}}>Eggs fried ,Chicken sausag <br/> Bacon, Baked beans, Toast</p>

</div>

<div > 
<img src={process.env.PUBLIC_URL + "/images/food3.jpg"} style={{ width:"120px",height:"135px", marginBottom:"-10px"}}/>
    <br/>    <button style={{width:"100px", backgroundColor:"#edf7dd",border:"none",outline:"none", marginLeft:"10%", color:"#759246", borderRadius:"5px"}}><strong>Add</strong></button>

</div>


</div> */}

        <Footer />
        <Modal
          show={show2}
          onHide={handleClose2}
          aria-labelledby="contained-modal-title-bottom"
        >
          <Modal.Header closeButton>
            <h5>{temp.itemname}</h5>

            {/* <Button onClick={handleClose2} id="btn-modal-footer" style={{backgroundColor:"#a2c760" , width:"75px"}}>Add</Button> */}
            <br />
          </Modal.Header>

          <Modal.Body>
          <div
  className="row"
  style={{ overflow: "scroll", height: "400px" }}
>
  {extraItem?.map((addonGroup, index) => (
    <div key={index}>
      <p className="d_main_title d_box">
        {addonGroup.addongroup_name}
      </p>
      <h6>You can choose multiple options</h6>
      {addonGroup?.addongroupitems?.map((addonItem, index) => (
        <div className="addOnItems_block" key={index}>
          <div>
            <input
              type="checkbox"
              value={addonItem.addonitem_name}
              checked={selectedAddons.includes(addonItem)} // Corrected: check if addonItem is in selectedAddons
              onChange={() => onUpdateField(addonItem)} // Handle change with onUpdateField
              className="Addoncustom-checkbox"
            />
            <label style={{ paddingLeft: "10px" }}>
              {addonItem.addonitem_name}
            </label>
          </div>

          <span>Rs {addonItem.addonitem_price}</span>
        </div>
      ))}
    </div>
  ))}
</div>
          </Modal.Body>
          <Modal.Footer>
            <button
              variant="secondary"
              onClick={() => {
                onAdd(temp, selectedAddons);
                handleClose2();
              }}
              className="add-on-more-btn"
            >
              Add
            </button>
          </Modal.Footer>
        </Modal>
        {/* <div
          onClick={handleShow}
          style={{
            width: "80px",
            textAlign: "center",
            borderRadius: "5px",
            backgroundColor: "#A2C760",
            position: "fixed",
            bottom: "0",
            marginBottom: "60px",
            marginLeft: "37%",
            color: "white",
            fontSize: "20px",
          }}
        >
  
          Menu
        </div> */}
      </div>

      {/* Modal */}

      <Modal show={show} onHide={handleClose} style={{ marginTop: "100px" }}>
        <Modal.Header closeButton>
          <Modal.Title className="d_main_title text-center">Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Previous ordered item</Form.Label>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Recommended</Form.Label>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Popular in breakfast</Form.Label>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Chef special</Form.Label>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Breakfast</Form.Label>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Fresh salads</Form.Label>
            </Form.Group>

            <Form.Group>
              <Form.Label>Burgers</Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FoodOrder;

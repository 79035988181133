import React, { useState,useRef } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { apiBaseUrl } from '../config/constant'
import { setSessionForMobile } from '../utils/Helper'
import { useNavigate } from 'react-router-dom'

function CheckMobileNumberOtp({verifiedNumber}) {
  const navigate =useNavigate()
    const [loginpopup,setLoginpopup]=useState(true)
    const handleClose=()=>{setLoginpopup(false);navigate('/') }
    const [error, setError] = useState('');
  const [otpsection,setotpsection]=useState(false)
  const [otpValue, setOtpValue] = useState(new Array(6).fill('')); // Assuming 6-digit OTP
  const inputRefs = useRef([]);
    const [mobile,setMobile]=useState('')
    const[wrongOtp,setWrongOtp]=useState(null)
const [OTPfromResponse,setOTPfromResponse]=useState('')


    const isValidMobileNumber = (mobile) => {
      return /^\d{10}$/.test(mobile); // Simple regex pattern for 10-digit numbers
    };
    
    // Function to handle input change for each OTP input box
    const handleChange = (index, newValue) => {
      setWrongOtp(''); // Clear any error state
    
      // If the new value is a valid single digit
      if (/^\d$/.test(newValue)) {
        const newOtpValue = [...otpValue];
        newOtpValue[index] = newValue;
        setOtpValue(newOtpValue);
    
        // Move to the next input field if a digit is entered
        if (index < otpValue.length - 1) {
          inputRefs.current[index + 1].focus();
        }
      } 
      // If the new value is empty (backspace or delete)
      else if (newValue === "") {
        const newOtpValue = [...otpValue];
        newOtpValue[index] = ""; // Clear the current value
        setOtpValue(newOtpValue);
    
        // Move to the previous input field if backspace is pressed
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      }
    };
    

    const handleKeyDown = (index, e) => {
      if (e.key === 'Backspace') {
        const newOtpValue = [...otpValue];
  
        // If the current input is empty, move to the previous input
        if (!newOtpValue[index] && index > 0) {
          inputRefs.current[index - 1].focus();
        }
  
        // Remove the value and focus previous input
        newOtpValue[index] = '';
        setOtpValue(newOtpValue);
      }
    };
    
  
    // Render OTP input boxes
    const renderInputs = () => {
      return             otpValue.map((value, index) => (
        <li key={index} style={{ listStyle: 'none', display: 'inline' }}>
        <input
          ref={(ref) => (inputRefs.current[index] = ref)}
          type="tel" // Use type="tel" to allow only numeric input
          pattern="[0-9]*" // Set pattern to allow only numeric characters
          inputMode="numeric" // Ensure numeric keyboard on mobile devices
          placeholder="X"
          value={value}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)} // Handle backspace
          className="otp-input"
          maxLength={1} // Limit input to a single character
          style={{
            width: '40px',
            height: '40px',
            textAlign: 'center',
            margin: '0 5px',
            fontSize: '18px',
            border: '1px solid #ced4da',
            borderRadius: '4px',
          }}
        />
      </li>
        
        ))
    };
     const validateOTP = (otp) => {
      const enteredOTP = otp.join("");
      if (enteredOTP.length === 6) {
        // Here you can call your API with the OTP
        console.log("Valid OTP:", enteredOTP);
        return true;
        // Call your API here using enteredOTP
      } else {
        console.log("Invalid OTP");
      }
    };
    const handleMobileChange = (e) => {
      const MobValue = e.target.value;
      setMobile(MobValue);
      setError('');  
    };
    const SendOtp=async()=>{
   if (otpsection) {
      handleLoginOtp();
      return;
    }

    if (!isValidMobileNumber(mobile)) {
      setError('Please enter a valid 10-digit mobile number that doesn\'t start with 0');
      return;
    }
      if (!isValidMobileNumber(mobile)) {
        setError('Please enter a valid mobile number');
        return;
      }
      try {
        // Make POST request to the API endpoint
        const response = await axios.post(apiBaseUrl+'common/send-otp', {
          phoneNumber: mobile // Pass mobile number in the request body
        });
  
        // Handle response data
      if (response.data.success){
        setotpsection(true)
       setOTPfromResponse(response.data.otp)
      //  console.log(response.data,)
       }
      } catch (error) {
        // Handle error
        console.error('Error sending OTP:', error);
      }
    }
    const handleLoginOtp = async () => {
      // e.preventDefault();
      // setSessionForMobile('mobile',mobile)
      // setLoginpopup(false)
  if(wrongOtp ===null || wrongOtp =='Otp is required'){
  setWrongOtp('Otp is required')
  return
  }
      if (validateOTP(otpValue)) {
        const options = {
          url: "/common/verify-otp",
          postData: {
            phoneNumber: mobile,
            otp: otpValue.join(""),
          },
        };
  
        try {
          const response = await axios.post(apiBaseUrl+options.url,options.postData);
const  tempOTp=otpValue.join('')
if(response.data.success===true && tempOTp==OTPfromResponse){
  setLoginpopup(false)
  setSessionForMobile('mobile',mobile)
  verifiedNumber()
}
          // if (response && response.status === true) {
          //   if(UserData?.token){
          //     setSessionForLoginUser('User',response.result)
          //     sessionStorage.setItem('TOKEN',response.result.token)
          //   if(UserData?.firstName&& UserData?.email && UserData?.mobile && UserData?.gender){
          //     set_otp(false);
          //     navigate(1)
          //   }else{
          //     set_address(true);
          //     setShow(false);
          //     set_otp(false);
          //   }
          // }
  
          // } else {
          //   setWrongOtp("Invalid OTP");
          //   // Handle invalid OTP case
          // }
        } catch (error) {
          setWrongOtp("Invalid OTP");
          console.error("Error:", error);
          // Handle error if any
        }
      } else {
        setWrongOtp("Invalid OTP");
        // Handle invalid OTP case
      }
    };
  return (
    <>
    
    <Modal show={loginpopup} onHide={handleClose}  centered backdrop="static" >

  <Modal.Body className={`Mob_Otp_popup  ${!otpsection ? "ViewInputMob" : "viewotpInput"}`} style={{width:"333px" }} >
    
    <div  style={{padding:"0"}}>
        <img src={process.env.PUBLIC_URL+'/images/icons/cross.png'} alt="cross" onClick={handleClose} />
        {/* {!otpsection? <>
        
      <h5>Enter Your Phone Number</h5>
      <div className="input-field">
        <input type="tel" id="phoneNumber" placeholder="Enter your phone number" required   onChange={handleMobileChange}/>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
      </>:''} */}
     {!otpsection? <>
      <h6 className='d-flex'> Enter your phone number</h6>
    <div className="form-group" style={{ display: "flex", alignItems: "center"}}>
  <input 
    className="CreateEventForm_formField__G1FTy" 
    onChange={handleMobileChange} 
    type="tel" id="phoneNumber" placeholder="Enter your phone number"   
    style={{
      flex: "1",
      padding: "10px",
      marginRight: "10px",
      borderRadius: "4px",
      border: "1px solid #ced4da",
      fontSize: "14px",
      height: "40px",
      boxSizing: "border-box",
         
    }}
  />



  <div 
    // className="check-btn-pin" 
    onClick={SendOtp} 
    style={{
      padding: "10px 20px",
      backgroundColor: "#a2c760",
      color: "#fff",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "14px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box"
    }}>
    Proceed
  </div>
</div>
{error && <p style={{ color: 'red' ,fontSize:"13px"}}>{error}</p>}
</> :""}

      {otpsection?
      <>
      <h6>Enter OTP</h6>
      <div className="otp-container mb-3">{renderInputs()}</div>
      {wrongOtp ? <p className='error_Validtaion' style={{color:"red"}}>{wrongOtp}</p> : null}
      </>
        :''}
      <div className="input-field">
     
        {otpsection?<button onClick={SendOtp}>Submit</button> :''}
      </div>
    </div>


  </Modal.Body>
</Modal>
    </>
  )
}

export default CheckMobileNumberOtp